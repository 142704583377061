<template>
    <div>
        <b-modal ref="popup-form-usuariosetor"
                 hide-footer centered fixed size="sm"
                 title="Setor de Usuarios">

            <b-container fluid>

                <b-row class="mb-3">
                    <input-select label="Setor *" :opcoes="setores" v-model="setorId" />
                    <p class="txt-validacao-erro m-0" v-if="submitted && !setorId">Selecione um setor</p>
                </b-row>

                <b-row class="mb-3">
                    <input-select label="Canal *" :opcoes="canais" v-model="canalId" />
                    <p class="txt-validacao-erro m-0" v-if="submitted && !canalId">Selecione um canal</p>
                </b-row>

                <b-row class="mb-3">
                    <input-select label="Prioridade *" :opcoes="prioridades" v-model="prioridade" />
                </b-row>

                <b-row>
                    <input-select label="Habilitado? *" :opcoes="[{value:true,text:'Sim'},{value:false,text:'Não'}]" v-model="habilitado" />
                </b-row>

            </b-container>
            <div class="w-100 mt-4 text-right">
                <botao-modal-cancel class="d-inline mr-2" @click="fecharModal">Cancelar</botao-modal-cancel>
                <botao-modal-ok class="d-inline" @click="salvarUsuarioSetor">Salvar</botao-modal-ok>
            </div>

        </b-modal>
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapGetters, mapActions } from 'vuex'
    import InputSelect from '@/components/inputs/InputSelect.vue'
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue'
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue'

    export default {
        name: 'UsuarioSetorFormPopup',

        components: {
            InputSelect,
            BotaoModalOk,
            BotaoModalCancel
        },

        data() {
            return {
                id: null,
                setorId: '',
                canalId: '',
                fechar: false,
                prioridade: '',
                prioridades:[],
                habilitado: true,
                usuarioId: 0,
                empresaId: 0,

                submitted: false
            }
        },

        computed: {
            ...mapGetters({
                listaSetores: 'setores/listaSetores',
                listaCanais: 'canais/listaCanais',
            }),
            setores: function () {
                if (this.listaSetores) {
                    return this.listaSetores.map(prop => ({
                        value: prop.Id,
                        text: prop.Nome
                    }))
                }
                return []
            },
            canais: function () {
                if (this.listaCanais) {
                    return this.listaCanais.map(prop => ({
                        value: prop.Id,
                        text: prop.Nome
                    }))
                }
                return []
            },
        },

        created() {
            this.buscarSetores();
            this.buscarCanais();
            this.buscaPrioridades();
        },

        methods: {
            ...mapActions({
                buscarSetores: 'setores/buscaSetores',
                buscarCanais: 'canais/buscaCanais',
                mostrarSucesso: 'mostradorDeMensagens/mostrarSucesso',
                mostrarErro: 'mostradorDeMensagens/mostrarErro',
            }),
            showModal() {
                this.$refs['popup-form-usuariosetor'].show();
                return new Promise(resolve => this.fechar = resolve);
            },
            editarUsuarioSetor: function (usetor) {
                this.limparFormulario();
                this.id = usetor.Id,
                this.setorId = usetor.SetorId;
                this.canalId = usetor.CanalId;
                this.prioridade = this.prioridades.find(p =>
                    p["text"] == usetor.Prioridade).value;
                this.habilitado = usetor.Habilitado;
                this.usuarioId = usetor.UsuarioId;
                this.empresaId = usetor.EmpresaId;
            },
            buscaPrioridades(){
                axios.get(`api/usuariosetor/prioridades`).then(response => {
                    let listaPrioridades = response.data;
                    if (listaPrioridades) {
                        this.prioridades = listaPrioridades.map(prop => ({
                            value: prop.Enum,
                            text: prop.Text
                        }))
                    }
                }).catch(error => {
                    console.error(error);
                });

            },
            setDadosNovoSetor: function (dados) {
                this.limparFormulario();
                let usuarioAutenticado = JSON.parse(localStorage.getItem('login'));
                this.empresaId = usuarioAutenticado.empresaId;
                this.usuarioId = dados.Id;
            },
            fecharModal: function () {
                this.$refs['popup-form-usuariosetor'].hide();
                this.limparFormulario();
            },
            limparFormulario: function () {
                this.submitted = false;
                this.id = null;
                this.setorId = '';
                this.canalId = '';
                this.prioridade = '';
                this.habilitado = true;
                this.usuarioId = 0;
                this.empresaId = 0;
            },
            salvarUsuarioSetor: async function () {
                this.submitted = true;
                if (!this.canalId || !this.setorId) {
                    return
                }

                let dados = {
                    "Id": this.id,
                    "SetorId": this.setorId,
                    "CanalId": this.canalId,
                    "Prioridade": this.prioridade,
                    "Habilitado": this.habilitado,
                    "UsuarioId": this.usuarioId,
                    "EmpresaId": this.empresaId,
                }

                try {
                    if(this.id){
                        await axios.put('api/usuariosetor', dados).then(() => this.fechar(true));
                    }
                    else{
                        await axios.post('api/usuariosetor', dados).then(() => this.fechar(true));
                    }
                    this.$bvToast.toast('Informações Salvas', {
                        title: `Aviso`,
                        toaster: 'b-toaster-top-right',
                        solid: true,
                        variant: 'success',
                        appendToast: false,
                        autoHideDelay: 3000,
                        noCloseButton: true
                    })
                   
                } catch(err) {
                    if(err.response) {
                        this.mostrarErro(err.response.data.Mensagem)
                    }
                    if(!err.response) {
                        this.mostrarErro('Ocorreu um erro ao tentar salvar as informações')
                    }
                }
                this.fecharModal();
            },
        }
    }
</script>

<style scoped>
    label {
        font-size: 14px;
        margin: 5px 0 2px 0;
    }

    .txt-validacao-erro {
        color: red;
        font-size: 12px;
    }
</style>